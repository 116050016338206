<template>
    <div class="page">
        <div class="page-header pd">
            <sc-button icon="plus" @click="$router.push(`new`)">Add {{ resource.name }}</sc-button>
            <!--            <sc-search :table="table"></sc-search>-->
        </div>
        <sc-table :table="table" v-if="table">

            <template #column-created_by="{row}">
                <sc-table-cell :table="table" field="created_by" :padded="false" :clickable="false">
                    {{ getName(row) }}
                </sc-table-cell>
            </template>

            <template #column-edit="{row}">
                <sc-table-cell :table="table" field="edit" :padded="false" :clickable="false">
                    <sc-table-button @click="edit(row)" icon="edit">
                    </sc-table-button>
                </sc-table-cell>
            </template>

            <template #column-delete="{row}">
                <sc-table-cell :table="table" field="delete" :padded="false" :clickable="false">
                    <sc-table-button-delete @destroy="destroy(row)">
                        Do you wish to delete the {{ resource.name }} "{{ row.attributes.name }}"?
                    </sc-table-button-delete>
                </sc-table-cell>
            </template>


        </sc-table>
    </div>
</template>

<script>
import ScSearch from "@/components/sc-search";

export default {
  name: "all",
  components: {ScSearch},

  data() {
    return {
      table: null
    }
  },

  props: {
    passed: {
      required: true,
    }
  },

  computed: {
    resource() {
      return this.passed
    }
  },

  mounted() {
    this.table = this.$stable.createTable({
      name: `List of ${this.resource.plural}`,
      url: this.resource.api,
      fetchArguments:()=>{
        return '&category=ois'
        // return '&per_page=10'
      },

      columns: {
        id: {sort: false},
        scala_id: {
          target: 'attributes.scala_id',
          sort: false
        },
        name: {
          width: 300,
          target: 'attributes.name',
          sort: false
        },
        description: {
          fill: true,
          target: 'attributes.description',
          sort: false
        },
        template_id: {
          name: 'design id',
          target: 'attributes.template_id',
          sort: false
        },
        created_by: {
          sort: false
        },
        edit: {width: 70, sort: false},
        delete: {width: 80, sort: false}
      },
      hover: false,
    });

    this.table.fetch()
  },

  methods: {
    search() {
      this.table.fetch();
    },

    destroy(row) {
      this.$talker.api.delete(`${this.resource.api}/${row.id}`)
        .then(res => {
          this.table.fetch();
          this.$notify.success(`${row.attributes.name} deleted`)
        })
    },

    edit(row) {
      this.$router.push(`${row.id}/edit`)
    },

    getName(row) {
      if (row.hasOwnProperty('created_by') && row.created_by.hasOwnProperty('data')) {
        return `${row.created_by.data.attributes.first_name} ${row.created_by.data.attributes.last_name}`;
      }
      return "n/a";
    }
  }
}
</script>

<style scoped lang="scss">
.btns {
    text-align: center;
    padding: 2em 0 0.5em;
}
</style>